<template>
  <div
    v-if="phone || noPhone"
    id="profile"
  >
    <div class="profile-container">
      <h2 class="profile-heading">
        ACCOUNT INFORMATION
      </h2>
      <div>
        <div>
          <div class="profile-left">
            <h3 class="profile-subtitle">
              Name
            </h3>
            <span class="profile-body">{{ firstName }} {{ lastName }}</span>
          </div>
          <div class="profile-right">
            <b-btn
              v-if="firstName || lastName"
              aria-label="click to edit name"
              class="profile-edit-link"
              variant="link"
              @click="clickEditName"
            >
              <i class="material-icons">edit</i>
            </b-btn>
            <b-btn
              v-if="!firstName && !lastName"
              aria-label="click to add name"
              class="profile-edit-link profile-add-link"
              variant="link"
              size="sm"
              @click="clickEditName"
            >
              ADD
            </b-btn>
          </div>
          <div class="horizontal-divider" />
        </div>
        <div>
          <div class="profile-left">
            <h3 class="profile-subtitle">
              Mobile Number
            </h3>
            <span class="profile-body">{{ phone }}</span>
          </div>
          <div class="profile-right">
            <b-btn
              v-if="!noPhone"
              aria-label="click to edit phone number"
              class="profile-edit-link"
              variant="link"
              @click="clickEditPhone"
            >
              <i class="material-icons">edit</i>
            </b-btn>
            <b-btn
              v-if="!phoneVerified && !noPhone"
              aria-label="click to verify phone number"
              class="profile-edit-link"
              variant="link"
              size="sm"
              @click="clickVerifyPhone"
            >
              VERIFY
            </b-btn>
            <b-btn
              v-if="noPhone"
              aria-label="click to add phone number"
              class="profile-edit-link profile-add-link"
              variant="link"
              size="sm"
              @click="clickEditPhone"
            >
              ADD
            </b-btn>
          </div>
          <div class="horizontal-divider" />
        </div>
        <div>
          <div class="profile-left">
            <h3 class="profile-subtitle">
              Email Address
            </h3>
            <span class="profile-body">{{ email }}</span>
          </div>
          <div class="profile-right">
            <b-btn
              v-if="!noEmail"
              aria-label="click to edit email address"
              class="profile-edit-link"
              variant="link"
              @click="clickEditEmail"
            >
              <i class="material-icons">edit</i>
            </b-btn>
            <b-btn
              v-if="noEmail"
              aria-label="click to add email address"
              class="profile-edit-link profile-add-link"
              variant="link"
              size="sm"
              @click="clickEditEmail"
            >
              ADD
            </b-btn>
          </div>
          <div class="horizontal-divider" />
        </div>
        <div>
          <div class="profile-left">
            <h3 class="profile-subtitle">
              Agent Language
            </h3>
            <span class="profile-body">{{ languageText }}</span>
          </div>
          <div class="profile-right">
            <b-btn
              aria-label="click to edit language"
              class="profile-edit-link"
              variant="link"
              @click="clickEditLanguage"
            >
              <i class="material-icons">edit</i>
            </b-btn>
          </div>
          <div class="horizontal-divider" />
        </div>
        <div v-if="!isAmazonDevice">
          <div class="profile-left">
            <h3 class="profile-subtitle">
              Payment Method
            </h3>
            <span
              v-if="ccLastFour"
              class="profile-body"
            >
              {{ paymentText }}
            </span>
          </div>
          <div class="profile-right">
            <b-btn
              v-if="ccLastFour"
              aria-label="click to edit payment method"
              class="profile-edit-link"
              variant="link"
              @click="clickEditPayment"
            >
              <i class="material-icons">edit</i>
            </b-btn>
            <b-btn
              v-if="!ccLastFour"
              aria-label="click to add payment method"
              class="profile-edit-link profile-add-link"
              variant="link"
              size="sm"
              @click="clickEditPayment"
            >
              ADD
            </b-btn>
          </div>
          <div class="horizontal-divider" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAgentMixin from '../../mixin/UserAgentMixin';

export default {
  name: 'UserProfile',
  mixins: [
    UserAgentMixin,
  ],
  computed: {
    ...mapGetters([
      'email',
      'noEmail',
      'phone',
      'noPhone',
      'phoneVerified',
      'firstName',
      'lastName',
      'cardType',
      'ccLastFour',
      'language',
      'englishSecondLanguage',
    ]),
    paymentText() {
      return `${this.cardType} ending in ${this.ccLastFour}`;
    },
    languageText() {
      if (this.language === 'French') {
        return this.englishSecondLanguage ? 'Français ou Anglais' : 'Français';
      } else if (this.language === 'Spanish') {
        return this.englishSecondLanguage ? 'Español o Inglés' : 'Español';
      } else {
        return this.language;
      }
    },
  },
  methods: {
    clickEdit() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit`);
    },
    clickEditName() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit/name`);
    },
    clickEditEmail() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit/email`);
    },
    clickEditPhone() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit/phone`);
    },
    clickVerifyPhone() {
      this.$store.dispatch('reverifyPhone');
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/verify/phone`);
    },
    clickEditPayment() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit/billing`);
    },
    clickEditLanguage() {
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/edit/language`);
    },
  },
};
</script>

<style lang="scss">

#profile {
  .profile-container {
    height: 100%;
    text-align: left;
    padding-top: 30px;
    padding-left: 20px;
  }
  .profile-left {
    display: inline-block;
    width: 60%;
    line-height: 16px;
  }
  .profile-right {
    display: inline-block;
    width: 40%;
  }
}
</style>
